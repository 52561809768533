export interface SingleFileUploadWithProgressProps {
  file: File;
  onDelete: (file: File) => void;
}

export function UploadFileRow({
  file,
  onDelete,
}: SingleFileUploadWithProgressProps) {
  return (
    <div className="file-row">
      <p className="file-name">{file.name}</p>
      <button
        className="delete-btn"
        onClick={(event) => {
          event.stopPropagation();
          onDelete(file);
        }}
      >
        Delete
      </button>
    </div>
  );
}
