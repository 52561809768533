interface UploadFilesPayload {
  files: File[];
  codeSnippetStart: string;
  codeSnippetEnd: string;
  utmPropName: string;
  onResponseLoaded?: () => void;
}

export const uploadFiles = async ({
  files,
  codeSnippetStart,
  codeSnippetEnd,
  utmPropName,
  onResponseLoaded = () => {},
}: UploadFilesPayload) => {
  const url = process.env.REACT_APP_BACKEND_URL || '';

  const formData = new FormData();
  files.forEach((file) => formData.append("file", file));
  formData.append('snippetStart', codeSnippetStart);
  formData.append('snippetEnd', codeSnippetEnd);
  formData.append('utm', utmPropName);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  onResponseLoaded();

  if (!response.ok) {
    return response.statusText
      ? `Something went wrong, cannot process your files. Error: ${response.statusText}`
      : 'Oops, something went wrong on a server side';
  }

  const blob = await response.blob();

  const downloadUrl = window.URL.createObjectURL(
    new Blob([blob]),
  );
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute(
    'download',
    'output.zip',
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link?.parentNode?.removeChild(link);

}
