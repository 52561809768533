import { ChangeEvent, MouseEventHandler, useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { nanoid } from "nanoid";
import cn from "classnames";

import { UploadFileRow } from "./UploadedFIleRow";
import { uploadFiles } from "../../services/upload-service";
import { Loader } from "../Loader";

export interface UploadableFile {
  id: string;
  file: File;
}

export function UploadFileForm() {
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [codeSnippetEnd, setCodeSnippetEnd] = useState(
    "{{content_blocks.${footer_LANGUAGECODE_only_new}}}"
  );
  const [codeSnippetStart, setCodeSnippetStart] = useState(
    '{% connected_content https://braze-content.bigbrain.me :method post :headers { "Content-Type": "application/json" } :body userId={{${user_id}}}&slug={{custom_attribute.${slug}}}&accountId={{custom_attribute.${monday_account_id}}}&createdAt={{custom_attribute.${created_at}}} :save resurrect_magic_link :retry%}'
  );
  const [utmPropName, setUtmPropName] = useState('platform_utm_locale_id');

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const uploadedFiles = acceptedFiles.map((file) => ({
        file,
        id: nanoid(),
      }));
      const error = rejectedFiles?.[0]?.errors?.[0]?.message || "";

      setFiles((currentFiles) => [...currentFiles, ...uploadedFiles]);
      setError(error);
    },
    []
  );

  function onDelete(file: File) {
    setFiles((currentFiles) =>
      currentFiles.filter((fileWrapper) => fileWrapper.file !== file)
    );
  }

  const isNoFiles = !files.length;

  const { getRootProps, getInputProps } = useDropzone({
    disabled: isLoading,
    onDrop,
    accept: {
      "application/zip": [],
      "application/x-zip-compressed": [],
      "multipart/x-zip": [],
    },
  });

  const onResponseLoaded = () => {
    setIsLoading(false);
  };

  const onChangeStart = (event: ChangeEvent<HTMLInputElement>) => {
    setCodeSnippetStart(event.target.value);
  };

  const onChangeEnd = (event: ChangeEvent<HTMLInputElement>) => {
    setCodeSnippetEnd(event.target.value);
  };

  const onChangeUtmProp = (event: ChangeEvent<HTMLInputElement>) => {
    setUtmPropName(event.target.value);
  };

  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => event.stopPropagation();

  const onSubmit: MouseEventHandler = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsLoading(true);
    const error = await uploadFiles({
      files: files.map(({ file }) => file),
      codeSnippetStart,
      codeSnippetEnd,
      utmPropName,
      onResponseLoaded,
    });
    if (error) {
      setError(error);
    }
  };

  return (
    <form
      {...getRootProps()}
      className={cn(
        "upload-form",
        { "upload-form-empty": isNoFiles },
        { "upload-form-disabled": isLoading }
      )}
    >
      {isLoading && (
        <div className="backdrop" onClick={stopPropagation}>
          <Loader />
        </div>
      )}
      <input {...getInputProps()} disabled={isLoading} />
      <p className="title">
        Drag'n'drop your *.zip archives here, or click to select manually
      </p>
      <div className={isNoFiles ? "file-list-empty" : "file-list"}>
        {files.map((fileWrapper) => (
          <UploadFileRow
            key={fileWrapper.id}
            onDelete={onDelete}
            file={fileWrapper.file}
          />
        ))}
      </div>
      {!!error && <p className="error">{error}</p>}

      {!isNoFiles && (
        <>
          <p className="title">
            Input a code snippet that will be inserted after &lt;body&gt; tag
          </p>
          <div
            className={isNoFiles ? "file-list-empty" : "file-list"}
            onClick={stopPropagation}
          >
            <input
              value={codeSnippetStart}
              onChange={onChangeStart}
              className="input"
              onClick={stopPropagation}
              spellCheck="false"
              placeholder={
                'Code snippet, e.g. {% connected_content https://braze-content.bigbrain.me :method post :headers { "Content-Type": "application/json" } :body userId={{${user_id}}}&slug={{custom_attribute.${slug}}}&accountId={{custom_attribute.${monday_account_id}}}&createdAt={{custom_attribute.${created_at}}} :save resurrect_magic_link :retry%}'
              }
            />
          </div>
          <p className="title">
            Input a code snippet that will be inserted before &lt;/body&gt; tag
          </p>
          <div
            className={isNoFiles ? "file-list-empty" : "file-list"}
            onClick={stopPropagation}
          >
            <input
              value={codeSnippetEnd}
              onChange={onChangeEnd}
              className="input"
              onClick={stopPropagation}
              spellCheck="false"
              placeholder="Code snippet, e.g. {{content_blocks.${footer_LANGUAGECODE_only_new}}}"
            />
          </div>
          <p className="title">
            Input a custom_attribute property name (e.g. platform_utm_locale_id)
          </p>
          <div
            className={isNoFiles ? "file-list-empty" : "file-list"}
            onClick={stopPropagation}
          >
            <input
              value={utmPropName}
              onChange={onChangeUtmProp}
              className="input"
              onClick={stopPropagation}
              spellCheck="false"
              placeholder="platform_utm_locale_id"
            />
          </div>
        </>
      )}
      {!isNoFiles && (
        <div>
          <button
            type="submit"
            className="submit-btn"
            onClick={onSubmit}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      )}
    </form>
  );
}
